<template>
  <div>
    <!-- 見出し -->
    <div
      id="cast_header_bar"
      class="pa-2 blue--text blue lighten-5 d-flex align-center"
    >
      <div class="big mr-4">{{ shopName }}様</div>
      <div>[ {{ userName }} ]</div>
    </div>

    <app-bar ref="refAppBar" @on_back="onBack"></app-bar>

    <v-tabs v-model="items" class="mb-3">
      <v-tabs-slider></v-tabs-slider>
      <v-tab v-for="tab in tabs" :key="tab.index" class="font-weight-bold">{{
        tab.name
      }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="items" touchless>
      <v-tab-item v-for="tab in tabs" :key="tab.index">
        <component
          v-bind:is="tab.component"
          @tabmove="tabMove"
          @reload="init"
          :shop_id="shop_id"
          :shop_sites="shopSiteList"
        ></component>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { ref, reactive, toRefs } from "@vue/composition-api";
import { useRouter } from "@/utils";
import { repositoryFactory } from "@/repository/repositoryFactory";
import ShopSiteComponent from "./component/shopSite.vue";
import SiteComponent from "./component/site.vue";
import appBar from "./appBar.vue";
import utilDate from "@/common/utilDate.js";

export default {
  components: {
    ShopSiteComponent,
    SiteComponent,
    appBar,
  },
  props: ["shop_id", "user_name", "shop_name"],
  setup(props, ctx) {
    const ShopRepository = repositoryFactory.get("shops");
    const ShopSitesRepository = repositoryFactory.get("shopSites");
    const { router } = useRouter();
    const items = ref();
    const tabs = [
      {
        index: 0,
        name: "更新媒体一覧",
        component: ShopSiteComponent,
      },
      {
        index: 1,
        name: "対応媒体一覧",
        component: SiteComponent,
      },
    ];
    const state = reactive({
      shopSiteList: [],
      userName: "",
      shopName: "",
    });

    const init = async () => {
      if (!props.shop_id) {
        alert("店舗IDがありません。");
        router.push({ name: "admin-shop" });
        return;
      }

      await ShopRepository.admin_get(props.shop_id)
        .then((response) => {
          if (response.data) {
            state.userName = response.data.user_name;
            state.shopName = response.data.name;
          }
        })
        .catch((error) => {
          throw "ERROR:ShopSitesRepository.delete (" + error + ")";
        });

      state.shopSiteList.splice(0);
      const params = {
        shop_id: props.shop_id,
      };
      await ShopSitesRepository.admin_list(params)
        .then((response) => {
          if (response.data) {
            Object.keys(response.data).forEach(function (key) {
              state.shopSiteList.push({
                "id": response.data[key].id,
                "shop_id": response.data[key].shop_id,
                "site_id": response.data[key].site_id,
                "auth_url": response.data[key].auth_url,
                "loginid": response.data[key].loginid,
                "password": response.data[key].password,
                "is_origin": response.data[key].is_origin,
                "proxy_server": response.data[key].proxy_server,
                "task_server_id": response.data[key].task_server_id,
                "proxy_server_label": response.data[key].proxy_server
                  ? "設定あり"
                  : "-",
                "config": response.data[key].config,
                "sort": response.data[key].sort,
                "is_active": response.data[key].is_active,
                "sites_id": response.data[key].sites_id,
                "sites_name": response.data[key].sites_name,
                "sites_short_name": response.data[key].sites_short_name,
                "sites_type": response.data[key].sites_type,
                "sites_com_name": response.data[key].sites_com_name,
                "sites_category": response.data[key].sites_category,
                "memo": JSON.parse(response.data[key].config).memo,
                "color": JSON.parse(response.data[key].config).color,
                "name":
                  response.data[key].sites_name +
                  (JSON.parse(response.data[key].config).memo != ""
                    ? " [ " + JSON.parse(response.data[key].config).memo + " ] "
                    : ""),
                "insert_date": utilDate.getDateFormatLong(
                  utilDate.convertSplitStrToDate(response.data[key].created_at)
                ),
                "delete_date": response.data[key].delete_date,
              });
            });
          }
        })
        .catch((error) => {
          throw "ERROR:ShopSitesRepository.delete (" + error + ")";
        });
    };

    init();

    const tabMove = (tabIndex) => {
      items.value = tabIndex;
      if (tabIndex == 0) {
        init();
      }
    };

    const onBack = () => {
      router.back();
    };

    return {
      props,
      ...toRefs(state),
      items,
      tabs,
      tabMove,
      init,
      onBack,
    };
  },
};
</script>
