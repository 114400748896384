<template>
  <div>
    <v-app-bar flat color="white" class="x-scroll">
      <div class="pa-2">
        <v-btn class="gray font-weight-bold" @click="onBack"
          ><v-icon left>mdi-arrow-left-circle</v-icon>戻る</v-btn
        >
      </div>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  setup(props, ctx) {
    const onBack = async () => {
      ctx.emit("on_back");
    };

    return {
      onBack,
    };
  },
};
</script>
