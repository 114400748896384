var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","persistent":"","width":"1000px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"disabled":_vm.loginCheckLoading}},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("媒体設定")])]),_c('v-card-text',{staticClass:"scrollable-content"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.item.id),expression:"item.id"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.item.id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.item, "id", $event.target.value)}}}),_c('ValidationObserver',{ref:"observer"},[_c('h3',[_vm._v("更新媒体名")]),_c('div',{staticClass:"mt-2 mb-2"}),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-application-outline","readonly":"","success":"","name":"name","label":"媒体名","outlined":"","hide-details":"false"},model:{value:(_vm.showSite.name),callback:function ($$v) {_vm.$set(_vm.showSite, "name", $$v)},expression:"showSite.name"}})],1)],1)],1),_c('h3',[_vm._v("ログイン設定")]),_c('div',{staticClass:"mt-2 mb-2"},[_vm._v(" 媒体（サイト）のログインに関する情報を設定してください。 "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hpLink),expression:"!hpLink"}]},[_c('v-btn',{staticClass:"font-weight-bold pa-1 d-inline",attrs:{"text":"","color":"primary","loading":_vm.loginCheckLoading,"disabled":_vm.loginCheckLoading},on:{"click":_vm.checkLogin}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.loginCheckButtonText)}})])],1)]),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"ログイン画面のURL","rules":"required|url|max:300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(!_vm.urlHide),expression:"!urlHide"}],attrs:{"prepend-icon":"mdi-cloud","error-messages":errors,"success":valid,"name":"auth_url","label":"ログイン画面のURL","outlined":""},model:{value:(_vm.item.auth_url),callback:function ($$v) {_vm.$set(_vm.item, "auth_url", $$v)},expression:"item.auth_url"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"ログインID","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"prepend-icon":"mdi-account-outline","error-messages":errors,"success":valid,"name":"auth_loginid","label":"ログインID","outlined":""},model:{value:(_vm.item.loginid),callback:function ($$v) {_vm.$set(_vm.item, "loginid", $$v)},expression:"item.loginid"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"パスワード","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"prepend-icon":"mdi-lock","error-messages":errors,"success":valid,"name":"auth_password","label":"パスワード","outlined":""},model:{value:(_vm.item.password),callback:function ($$v) {_vm.$set(_vm.item, "password", $$v)},expression:"item.password"}})]}}])})],1)],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.item.id != 0 && _vm.hpLink),expression:"item.id != 0 && hpLink"}]},[_c('h3',{attrs:{"id":"title_head"}},[_vm._v("ホームページ連携専用項目")]),_c('div',{staticClass:"mt-2 mb-2"},[_vm._v("ホームページ連携に関連する項目です。")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"4"}},[_c('general-button',{attrs:{"btn_type":"info","btn_block":"","btn_disabled":_vm.item.id == 0},on:{"click-event":_vm.getApiKey},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-key-plus")])]},proxy:true}])},[_vm._v("キー生成")])],1),_c('v-col',{attrs:{"cols":"6","sm":"4"}},[_c('general-button',{attrs:{"btn_type":"error","btn_block":"","btn_disabled":_vm.item.id == 0},on:{"click-event":_vm.deleteApiKey},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-key-remove")])]},proxy:true}])},[_vm._v("キー削除")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"readonly":"","success":true,"label":"ホームページ連携用APIキー","hint":_vm.item.id == 0
                      ? "新規登録時は利用できません。"
                      : "キー生成ボタンで連携用のAPIキーが表示されます。","persistent-hint":"","outlined":"","disabled":_vm.item.id == 0},model:{value:(_vm.shop_site_api_key.value),callback:function ($$v) {_vm.$set(_vm.shop_site_api_key, "value", $$v)},expression:"shop_site_api_key.value"}})],1)],1)],1)],1),_c('h3',[_vm._v("対応コンテンツ一覧")]),_c('div',{staticClass:"mt-2 mb-2"},[_vm._v(" 下記コンテンツの更新に対応しています。更新しないコンテンツはチェックを外してください。"),_c('br'),(!_vm.setting.esutama_site_id.includes(Number(_vm.item.site_id)))?_c('span',[_vm._v(" ※スケジュールにチェックを入れておくと毎日6時30分～7時の間に、登録されているスケジュールで自動更新します。 ")]):_c('span',{staticClass:"red--text"},[_vm._v(" ※スケジュールを自動更新する際は、スケジュールにチェックを入れて保存し、スケジュール管理画面で"),_c('span',{staticClass:"font-weight-bold"},[_vm._v("「エステ魂更新設定」")]),_vm._v("ボタンをクリックして時刻を設定して下さい。 ")])]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.siteContents,"item-key":"site_content_id","items-per-page":-1,"mobile-breakpoint":0,"hide-default-footer":"","dense":"","show-select":"","single-select":false,"no-data-text":"対応コンテンツがありません"},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.site_content_name",fn:function(ref){
                      var item = ref.item;
return [_c('span',{staticClass:"text-align-middle"},[_vm._v(" "+_vm._s(item.site_content_name))])]}},{key:"item.content_name",fn:function(ref){
                      var item = ref.item;
return [_c('v-icon',[_vm._v(" "+_vm._s(item.content_icon)+" ")]),_c('span',{staticClass:"text-align-middle"},[_vm._v(" "+_vm._s(item.content_name))])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('h3',{staticClass:"mt-12"},[_vm._v("個別設定")]),_c('div',{staticClass:"mt-2 mb-2"},[_vm._v("媒体個別の情報があれば入力してください。")]),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"メモ","rules":"max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
                      var valid = ref.valid;
return [_c('v-text-field',{attrs:{"prepend-icon":"mdi-text-box","error-messages":errors,"success":valid,"label":"メモ","outlined":""},model:{value:(_vm.item.memo),callback:function ($$v) {_vm.$set(_vm.item, "memo", $$v)},expression:"item.memo"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","id":"color-selector"}},[_c('ValidationProvider',{attrs:{"name":"カラー","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
                      var valid = ref.valid;
return [_c('v-text-field',{attrs:{"prepend-icon":"mdi-palette","hide-details":"","error-messages":errors,"success":valid,"label":"カラー選択","readonly":"","outlined":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-menu',{attrs:{"top":"","nudge-bottom":"105","nudge-left":"16","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('div',_vm._g({style:(_vm.swatchStyle(_vm.item))},on))]}}],null,true),model:{value:(_vm.item.menu),callback:function ($$v) {_vm.$set(_vm.item, "menu", $$v)},expression:"item.menu"}},[_c('v-card',[_c('v-card-text',{staticClass:"pa-0"},[_c('v-color-picker',{attrs:{"flat":"","show-swatches":"","mode":"hexa"},model:{value:(_vm.item.color),callback:function ($$v) {_vm.$set(_vm.item, "color", $$v)},expression:"item.color"}})],1)],1)],1)]},proxy:true}],null,true),model:{value:(_vm.item.color),callback:function ($$v) {_vm.$set(_vm.item, "color", $$v)},expression:"item.color"}})]}}])})],1)],1)],1),_c('h3',[_vm._v("個別のサーバ設定")]),_c('div',{staticClass:"mt-2 mb-2"},[_vm._v(" 共用サーバを使用せず、個別サーバを経由して媒体を更新します。"),_c('span',{staticClass:"red--text"},[_vm._v("運営管理画面のみ、編集可能。")])]),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('ValidationProvider',{attrs:{"name":"個別サーバ","rules":"ipv4port"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
                      var valid = ref.valid;
return [_c('v-text-field',{attrs:{"prepend-icon":"mdi-server","error-messages":errors,"success":valid,"readonly":false,"label":"個別サーバ","outlined":""},model:{value:(_vm.item.proxy_server),callback:function ($$v) {_vm.$set(_vm.item, "proxy_server", $$v)},expression:"item.proxy_server"}})]}}])})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-container',[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('general-button',{attrs:{"btn_color":"gray","btn_block":""},on:{"click-event":_vm.cancelDialog},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close-circle")])]},proxy:true}])},[_vm._v("キャンセル")])],1),_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('general-button',{attrs:{"btn_type":"info","btn_block":""},on:{"click-event":_vm.onSubmit},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")])]},proxy:true}])},[_vm._v("保存")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }